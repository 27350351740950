<template>
  <div class="wrapper">
    <common-header></common-header>
    <common-sidebar></common-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <common-tags></common-tags>
      <div class="content">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <el-backtop target=".content"></el-backtop>
        <div class="icp-mps">
          <a v-if="miitCode" class="link" href="https://beian.miit.gov.cn" target="_blank">{{ miitCode }}</a>
          <a v-if="mpsCode" class="link" href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">{{ mpsCode }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonHeader from "./common/commonHeader";
import commonSidebar from "./common/commonSidebar";
import commonTags from "./common/commonTags";
import bus from "../service/bus";
import { queryOemDomain } from "@/api/oemConfig.js";

export default {
  components: {
    commonHeader,
    commonSidebar,
    commonTags,
  },
  data() {
    return {
      collapse: false,
      miitCode: "",
      mpsCode: "",
    };
  },
  async created() {
    console.log(this.keepAlive);
    // 控制折叠面板
    bus.$on("collapse-content", msg => {
      this.collapse = msg;
    });

    if (document.domain != "localhost" && document.domain != "crm.youdomain.com") {
      const oemConfig = await queryOemDomain({ oemDomain: document.domain });
      if (!oemConfig) {
        this.$message.error("oem配置错误,请联系售后");
        return;
      }
      this.setOemConfig(oemConfig);
    }
  },
  methods: {
    setOemConfig(oemConfig) {
      console.log(oemConfig);
      if (oemConfig && oemConfig.data) {
        //获取网页标题
        if (oemConfig.data.miitCode) {
          this.miitCode = oemConfig.data.miitCode;
        }
        if (oemConfig.data.mpsCode) {
          this.mpsCode = oemConfig.data.mpsCode;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icp-mps {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding-top: 30px;
  padding-bottom: 20px;
  .link {
    padding: 0 10px;
    color: #999;
  }
}
</style>
